<template>
<div v-if="Access('CarsView')" class="CookiesMainView">
  <div class="BigPlaceholder">
    <div class="BigContainer">
      <div class="BigHeader">
        <div class="D-Flex">
          <span class="F-Bold F1_75em BigHeaderText">{{LanguageSelector('Cars', 'السيارات')}}</span>
          <button v-if="Access('CarsAdd')" @click="CookiesAddCar()" :style="'background: ' + $store.getters.Theme.UserColors.main" class="DialogHeaderButton F1_25em">+ {{LanguageSelector('New Car', 'سيارة جديدة')}}</button>
        </div>
        <div class="D-Flex M-Auto CookiesSearchInputContainer" :style="$store.getters.Direction === 'ltr' ? 'margin-right: 0em;': 'margin-left: 0em;'">
          <input v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="LanguageSelector('Search...', 'بحث...')">
        </div>
      </div>
        <div class="BigBody P-Rel">
          <div v-if="Loading" class="P-Abs D-Flex W-100" style="height: 5em;">
            <div class="M-Auto">
              <CookiesLoading></CookiesLoading>
            </div>
          </div>
          <div class="TableBodyContainer">
            <div class=" CookiesRow">
              <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
                <tr>
                  <th></th>
                  <CookiesSortingTH @click="SortTable(['PlateNum'])" :Name="LanguageSelector('Plate Num', 'الرقم')" :Value="['PlateNum']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Name'])" :Name="LanguageSelector('Name', 'الاسم')" :Value="['Name']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['BarCode'])" :Name="LanguageSelector('Bar Code', 'كود السيارة')" :Value="['BarCode']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <CookiesSortingTH @click="SortTable(['Status'])" :Name="LanguageSelector('Status', 'الحالة')" :Value="['Status']" :sortColumn="sortElment" ></CookiesSortingTH>
                  <th></th>
                </tr>
                <tr v-if="!Loading & FilterdTable.length === 0"><td colspan="10" style="padding: 1.5em 0.2em;">{{LanguageSelector('No Cars', 'لا يوجد سيارات')}}</td></tr>
                <tr v-for='(value, index) in FilterdTable' :key='(value, index)' class="CookiesGeneralTableRow">
                  <td style="padding: 1em;"><strong>{{index + 1}}</strong></td>
                  <!-- <td>{{value['Type'] ? value['Type']: 'N/A'}}</td> -->
                  <td>{{value['PlateNum'] ? value['PlateNum']: 'N/A'}}</td>
                  <td style="cursor: text;" @blur="NameChanged(value['ID'])" :ref="value['ID'] + '_Name'" class="RowNoClick" :class="Access('CarsEdit') ? 'EditableCell' : ''" :contenteditable="Access('CarsEdit') ? 'true' : 'false'">{{value['Name'] ? value['Name']: 'N/A'}}</td>
                  <td>
                    <div v-if="value.BarCode !== undefined & value.BarCode !== null & value.BarCode !== ''" class="Shadow1" style="background: white; border-radius: var(--rad3); display: inline-block; padding-bottom: 0.75em; color: black;">
                      <vue-barcode style="width: 10em; height: 3em;" :value="value.BarCode !== undefined ? value.BarCode : ''" :options="{ displayValue: false, fontSize: 10, background: 'rgba(0,0,0,0)'}"></vue-barcode>
                      <br>
                      <span style="    display: block; margin-top: -1em; margin-bottom: -0.75em; padding-top: 0.5em;">{{value.BarCode}}</span>
                    </div>
                  </td>
                  <td><span @click="ChangeStatus(value)" :class="Access('CarsEdit') ? 'Pointer' : ''" class="ActiveButton F-Bold" :style="value['Status'].toLowerCase() === 'active' ? 'background: var(--colorg2)' : value['Status'].toLowerCase() === 'inactive' ? 'background: var(--colorr1)' : ''" style="padding: 0.5em; border-radius: var(--rad2); color: #010101;">{{value['Status'] ? value['Status']: 'N/A'}}</span></td>
                  <td>
                    <CookiesIcon ToolTip="Print Padge" class="CookiesRowIcon HoverGlow" @click="PrintBadge(value)" :ID="'Printer'"></CookiesIcon>
                    <CookiesIcon ToolTip="Delete" class="CookiesRowIcon HoverGlow" v-if="Access('CarsEdit')" @click="RemoveCar(value)" :ID="'Delete'"></CookiesIcon>
                  </td>
                </tr>
              </table> <!-- TABLE END --------------------- -->
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
//   created () {
//     window.addEventListener('resize', this.WindowChanged)
//   },
//   unmounted () {
//     window.removeEventListener('resize', this.WindowChanged)
//   },
  mounted () {
    // this.$store.state.CookiesMemory.Items = null
    this.ReadCars()
    // this.ReadPages()
    // this.ReadCategories()
    // this.WindowChanged()
  },
  data () {
    return {
      CookiesDataCars: [],
      Search: '',
      sortElment: {
        name: ['PlateNum'],
        value: 1
      },
      Loading: false
    }
  },
  computed: {
    FilterdTable () {
      var FilteredArray = []
      var SortedWithIDs = this.sortedCars.map(function (item) {
        item.Hashed_ID = '#' + item.ID
        return item
      })
      SortedWithIDs.forEach(item => {
        var FilterIt = true
        // if (this.$store.state.CookiesMemory.Cars_Filter_Type !== item.Car_Type && this.$store.state.CookiesMemory.Cars_Filter_Type !== 'All') {
        //   FilterIt = false
        // }
        // try {
        //   if (this.$store.state.CookiesMemory.Cars_Filter_Page.Page_Name !== item.Car_Page.Page_Name && this.$store.state.CookiesMemory.Cars_Filter_Page.Page_Name !== 'All') {
        //     FilterIt = false
        //   }
        // } catch (error) {
        // }
        // if (!this.$store.state.CookiesMemory.Car_Filter_Reserved && item.Car_Status === 'Reserved') { // ////// Reserved
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Car_Filter_Packaging && item.Car_Status === 'Packaging') { // ////// packaging
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Car_Filter_Delivery && item.Car_Status === 'Delivery') { // ////// Delivery
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Car_Filter_Delivered && item.Car_Status === 'Delivered') { // ////// Delivered
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Car_Filter_Fixing && item.Car_Status === 'NotPaid') { // ////// Fixing
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Car_Filter_Done && item.Car_Status === 'Done') { // ////// Done
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Car_Filter_Canceled && item.Car_Status === 'Canceled') { // ////// Done
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.Car_Filter_PriceHigh && item.Car_Total_Ammount < this.$store.state.CookiesMemory.Car_Filter_PriceHigh_Ammount) {
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.Car_Filter_PriceLow && item.Car_Total_Ammount > this.$store.state.CookiesMemory.Car_Filter_PriceLow_Ammount) {
        //   FilterIt = false
        // }
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedCars () {
      var Sorted = this.CookiesDataCars
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    }
  },
  methods: {
    ReadCars () {
      this.Loading = true
      this.Get('Cars').then(response => {
        if (response === null) {
          this.Loading = false
          return
        }
        this.CookiesDataCars = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Cars'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CookiesAddCar () {
      this.OpenDialog('NewCar', 'New Car', '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    PrintBadge (CAR) {
      this.OpenDialog('CarBadge', this.LanguageSelector('', ''), CAR, { header: false, background: 'none' }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenCar (Car) {
      if (event.target.classList.length > 0) {
        if ((event.target.classList.includes('RowNoClick'))) {
          return
        }
      }
      this.OpenDialog('EditCar', 'Edit Car ' + Car.PlateNum, Car) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortTable (name) {
      if (JSON.stringify(this.sortElment.name) === JSON.stringify(name)) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.sortElment.name = name
      }
    },
    NameChanged (ID) {
      if (!this.Access('CarsEdit')) {
        return
      }
      var OriginalItem = this.DeepCloner(this.ObjectFinder(this.CookiesDataCars, ['ID'], ID))
      var newName = this.$refs[ID + '_Name'].innerText.trim()
      if (newName.replace(/\n/g, '') === '') {
        this.$refs[ID + '_Name'].innerText = OriginalItem.Name
        return
      }
      var NewItem = this.DeepCloner(OriginalItem)
      NewItem.Name = newName
      var Patch = this.CookiesPatcher(OriginalItem, NewItem)
      if (Patch.length === 0) {
        return
      }
      this.Post('PATCH', 'Cars?ID=' + ID, Patch).then(response => {
        this.ReadCars()
      }, error => {
        error.CookiesError = 'Error in updating Car Name'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ChangeStatus (Car) {
      if (!this.Access('CarsEdit')) {
        return
      }
      var NewStatus = 'Active'
      if (Car.Status === 'Active') {
        NewStatus = 'Inactive'
      }
      this.Post('PATCH', 'Cars?ID=' + Car.ID, [{ value: NewStatus, path: '/Status', op: 'replace' }]).then(response => {
        this.ReadCars()
      }, error => {
        error.CookiesError = 'Error in updating Car Status'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveCar (Car) {
      if (!this.Access('CarsEdit')) {
        return
      }
      this.OpenDialog('DeleteDialog', this.LanguageSelector('Deleting Car', 'حذف سيارة'), ['Cars/Delete?ID=' + Car.ID, this.LanguageSelector('the car with number: ', 'السيارة رقم: ') + Car.PlateNum]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadCars()
    }
  }
}
</script>
<style scoped>

.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 0.85rem;
}
@media (max-width: 500px) {
  .BigHeader>>>*{
    font-size: 1.6em !important;
  }
  .StatusBar>>>*{
    font-size: 1.3em !important;
  }
}
@media (max-width: 1400px) {
  .BigPlaceholder>>>*{
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder>>>*{
    font-size: 1.2vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}
</style>
